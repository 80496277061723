/* eslint-disable no-console */
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';

// Utils
import getServerSideTranslations from '../src/utils/server-side';

// Services
import { useContentTranslation } from '../src/services/content-translations';
// Components
import PageLayout from '../components/templates/PageLayout/PageLayout';
import PageTransition from '../components/templates/PageTransition/PageTransition';
import Layout404 from '../components/templates/Layout404/Layout404';
import OneColumnBlock from '../components/atoms/OneColumnBlock/OneColumnBlock';

const ResidencesBlock = dynamic(() => import('../components/molecules/ResidencesBlock/ResidencesBlock'));

function Index({ locale }) {
  const { t } = useTranslation();

  const residences = useContentTranslation({ contentTypeId: 'page-residence', pageSize: 3 }, locale);

  const header = useContentTranslation({ contentTypeId: 'header', pageSize: 99 }, locale, {
    select: (data) => {
      const defaultHeader = data?.data?.filter((headerData) => headerData?.data?.isDefault);
      const headerData = defaultHeader?.length > 0 ? defaultHeader : [data?.data?.[0]];
      return { ...data, data: headerData };
    },
  });

  const footer = useContentTranslation({ contentTypeId: 'footer', pageSize: 99 }, locale, {
    select: (data) => {
      const defaultFooter = data?.data?.filter((footerData) => footerData?.data?.isDefault);
      const footerData = defaultFooter?.length > 0 ? defaultFooter : [data?.data?.[0]];
      return { ...data, data: footerData };
    },
  });

  return (
    <>
      <Head>
        <title>{t('404.metaTitle')}</title>

        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <PageLayout
        header={header?.data?.data?.[0]?.data}
        footer={footer?.data?.data?.[0]?.data}
      >
        <PageTransition>
          <Layout404>
            {!residences?.isLoading && residences?.data?.data?.length ? (
              <OneColumnBlock>
                <ResidencesBlock
                  title={t('404.residencesTitle')}
                  residences={residences?.data?.data || []}
                />
              </OneColumnBlock>
            ) : null}
          </Layout404>
        </PageTransition>
      </PageLayout>
    </>
  );
}

Index.propTypes = {
  locale: PropTypes.string.isRequired,
};

export async function getStaticProps({ locale }) {
  const translations = await getServerSideTranslations(locale);

  return {
    props: {
      ...translations,
    },
  };
}

export default Index;
