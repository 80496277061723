import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';
import Link from 'next/link';

// Utils
import { cn } from 'utils';
// Styles
import styles from './Content404.module.css';
// Constants
import { CONCEPT_SLUG, URL_SEARCH_PAGES } from '../../../src/constants';

function Content404({ theme, className }) {
  const { locale } = useRouter();
  const { t } = useTranslation();
  return (
    <div className={cn([styles.wrapper, styles[theme], className])}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{t('404.title')}</h1>
          <p className={styles.text}>{t('404.text')}</p>
          <div className={styles.links}>
            <Link
              className={styles.link}
              href="/"
              locale={locale}
            >
              {t('404.back')}
            </Link>
            <Link
              className={styles.link}
              href={`/${URL_SEARCH_PAGES[locale]}`}
              locale={locale}
            >
              {t('404.residencesFind')}
            </Link>
            <Link
              className={styles.link}
              href={`/${CONCEPT_SLUG}`}
              locale={locale}
            >
              {t('404.concept')}
            </Link>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <Image
            src="/images/samples/404.webp"
            alt="404"
            height="656"
            width="895"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
}

Content404.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

Content404.defaultProps = {
  theme: 'dark',
  className: '',
};

export default Content404;
